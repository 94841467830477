import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import WasteRegisterGenerator from '../../components/WasteRegisterGenerator'
import InstitutionEntity from '../../utils/InstitutionEntity'
import WasteRegisterSelector from '../../components/WasteRegisterSelector'
import { distinctArray, uniqueById, getArrayIds } from '../../utils/array-utils'

const WasteRegister = () => {
  const [finalList, setFinalList] = useState<string[]>([])
  const [selectedInstitutionIds, setSelectedInstitutionIds] = useState<
    string[]
  >([])
  const [selectedinstitutions, setSelectedInstitutions] = useState<
    InstitutionEntity[]
  >([])
  const handlefinalListRemove = (itemIds: string[]) => {
    // remove items from the list of selected institutions
    const myArray = finalList.filter(function (el) {
      return !itemIds.includes(el)
    })
    setFinalList(myArray)

    // remove items from the list of institutions Entity data
    const filteredListInstitutionsData = selectedinstitutions.filter(
      (instData: InstitutionEntity) => !itemIds.includes(instData.id)
    )
    setSelectedInstitutions(filteredListInstitutionsData)
  }
  useEffect(() => {
    setFinalList(distinctArray(selectedInstitutionIds))
  }, [selectedInstitutionIds])

  const { t } = useTranslation()
  return (
    <>
      <div className="wasteRegister_titleSection">
        <h2>{t('wasteRegister.mainTitle')}</h2>
        <span>{t('wasteRegister.info')}</span>
      </div>
      <div className="wasteRegister">
        <WasteRegisterSelector
          onInstitutionSelected={setSelectedInstitutionIds}
          onDataInstitutionSelected={setSelectedInstitutions}
          institutionItemsDatas={selectedinstitutions}
          finalKeptIds={finalList}
          setFinalList={setFinalList}
        />
        <WasteRegisterGenerator
          institutionsData={uniqueById(
            selectedinstitutions.filter((elem) => finalList.includes(elem.id))
          )}
          checkedList={getArrayIds(
            uniqueById(
              selectedinstitutions.filter((elem) => finalList.includes(elem.id))
            )
          )}
          selectedCount={finalList.length}
          handleRemoveitems={handlefinalListRemove}
        />
      </div>
    </>
  )
}

export default WasteRegister
