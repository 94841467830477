/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button as ButtonBootstrap } from 'react-bootstrap'
import Cookies from 'universal-cookie'
import jwt_decode from 'jwt-decode'
import axios from 'axios'
import Button from './Button'
import logo from '../assets/logo.png'
import {
  hasAccessTo,
  roleNamesToNumber,
  userAdminLandingPage,
  canManageDocuement,
  userSuperAdminLandingPage,
} from '../services/roles-services'
import {
  DecodedToken,
  dispatchUserInformations,
} from '../services/user-services'
import { AppContext } from '../store/context'
import MenuItem from './MenuItem'
import MenuSubItem from '../utils/MenuSubItem'
import SvgIcon from './SvgIcon'
import ROUTES from '../utils/ROUTES'
import ROLES from '../utils/ROLES'
import ROLES_ACCESS from '../utils/ROLES_ACCESS'
import { isSubMenu } from '../services/menu-services'
import SubMenu from './SubMenu'
import MainMenu from './MainMenu'
import { ActionTypes } from '../store/actions'

import handleNavigation from '../utils/RedirectionUtils'
import Color from '../utils/COLORS'
import Loading from './Loading'
import { searchOrganizationByIds } from '../services/organization-services'
import { getEntreprisesByIds } from '../services/institution-services'
import { hasAccessToManageServices } from '../services/user-services-services'
import NotificationMenu from './Notifications/NotificationMenu'

function Menu() {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const {
    state,
    state: {
      impersonnation: { isImpersonnation },
      user: { accessDemands, email },
      institutionPerimeter,
    },
    dispatch,
    updateRoleName,
  } = React.useContext(AppContext)
  const history = useHistory()
  const [sideBarOpen, setSideBarOpen] = useState(false)
  const [itemCollapsed, setItemCollapsed] = useState('')
  const [isLoading, setIsLoading] = React.useState(false)
  const handleitemCollapsed = (id: string) => {
    if (itemCollapsed === id) {
      setItemCollapsed('')
    } else {
      setItemCollapsed(id)
    }
  }
  const ACCESS_DEMANDS = Boolean(
    parseInt(process?.env?.REACT_APP_ACCESS_DEMANDS || '1', 10)
  )

  useEffect(() => {
    setSideBarOpen(false)
  }, [pathname])

  const { firstname, roleNames, isInternal } = state.user

  const currentRoles = roleNamesToNumber(roleNames)

  const hasAccessToServices = hasAccessToManageServices(
    isInternal,
    institutionPerimeter
  )

  const activitiesMenuItems: Array<MenuSubItem> = [
    { title: t('menu.menu1.subtitle1'), link: ROUTES.ActivitySynthesis },
    { title: t('menu.menu1.subtitle2'), link: ROUTES.ActivityAnalyseEvolution },
    { title: t('menu.menu1.subtitle3'), link: ROUTES.WasteRegister },
  ]
  const billingMenuItems: Array<MenuSubItem> = [
    { title: t('menu.menu2.subtitle1'), link: ROUTES.Billing },
    { title: t('menu.menu2.subtitle2'), link: ROUTES.BillingSynthesis },
    { title: t('menu.menu2.subtitle3'), link: ROUTES.BillingAnalyseEvolution },
  ]
  const userMenuItems: Array<MenuSubItem> = [
    {
      title: [
        ROLES.ROLE_INT_SUPER_ADMIN,
        ROLES.ROLE_INT_SUPPORT_N1,
      ].some((role) => currentRoles.includes(role))
        ? t('menu.menu3.subtitle1_bis')
        : t('menu.menu3.subtitle1'),
      link: ROUTES.Profile,
    },
  ]
  if (hasAccessTo(ROLES_ACCESS.adminUsersRoles, currentRoles)) {
    userMenuItems.push({
      title: t('menu.menu3.subtitle6'),
      link: ROUTES.UsersManagement,
    })
  }
  if (hasAccessTo(ROLES_ACCESS.superAdminPageRole, currentRoles)) {
    userMenuItems.push({
      title: t('menu.menu3.subtitle9'),
      link: ROUTES.SuperAdminUsersManagement,
    })
  }
  if (canManageDocuement(roleNames, state.user.accessDocs)) {
    userMenuItems.push({
      title: t('menu.menu3.subtitle4'),
      link: ROUTES.DocumentsManagement,
    })
  }

  if (hasAccessToServices) {
    userMenuItems.push({
      title: t('menu.menu3.subtitle8'),
      link: ROUTES.MyServices,
    })
  }

  userMenuItems.push({
    title: t('menu.menu3.subtitle7'),
    link: ROUTES.ContactUs,
  })
  userMenuItems.push({
    title: t('menu.menu3.subtitle5'),
    link: ROUTES.CheckEmail,
  })

  const menuClassname = sideBarOpen ? 'menu menu--opened' : 'menu menu--closed'

  const handleNavigationMenu = (rolesNames: string[]) => {
    // tarnslate roles to numbers
    const userManager = userAdminLandingPage(rolesNames)
    const superAdmin = userSuperAdminLandingPage(roleNames)

    if (userManager) {
      return ROUTES.UsersManagement
    }
    if (superAdmin) {
      return ROUTES.SuperAdminUsersManagement
    }
    return ROUTES.Dashboard
  }

  return (
    <>
      <div className="menu__burger">
        <ButtonBootstrap
          variant="transparent"
          onClick={() => setSideBarOpen(!sideBarOpen)}
        >
          <SvgIcon name="menu" className="menu-icon" />
        </ButtonBootstrap>
        <MenuItem
          title={
            <>
              <img alt="logo Suez" src={logo} className="menu__logo" />
            </>
          }
          link={handleNavigationMenu(state.user.roleNames)}
        />
      </div>
      <div className={menuClassname}>
        <MenuItem
          title={
            <>
              <img alt="logo Suez" src={logo} className="menu__logo" />
            </>
          }
          link={handleNavigationMenu(state.user.roleNames)}
        />
        {isImpersonnation && (
          <Button
            className=" menu__impersonnationExit"
            onClick={async () => {
              setIsLoading(true)
              dispatch({
                type: ActionTypes.SET_API_CALL_BLOCK,
                payload: {
                  APICallBlocked: true,
                },
              })

              const cookies = new Cookies()
              // Handle perimeter save in cookies
              const institutionsList = cookies.get('chosenInstitution')
              const organizationsList = cookies.get('chosenOrganization')

              sessionStorage.removeItem('impersonation_token')
              sessionStorage.removeItem('refresh_token')
              const accessToken = localStorage.getItem('access_token') || ''
              const decodedToken: DecodedToken = jwt_decode(accessToken)
              const userData = {
                username: decodedToken.username,
                userId: decodedToken.user_id,
                isInternal: Boolean(decodedToken.isInternal),
                organizations: decodedToken.organizations.map((org) => org.id),
                roleNames: decodedToken.roles.map((e) => e.name),
                firstname: decodedToken.first_name,
                accessDemands: Boolean(decodedToken.access_demands),
                accessDocs: Boolean(decodedToken.access_docs),
                idContactWdh: decodedToken.id_contact_wdh,
                isSuperAdminTech: Boolean(decodedToken.is_super_admin_tech),
              }
              axios.interceptors.request.clear()
              axios.defaults.headers.common = {
                Authorization: `Bearer ${accessToken}`,
              }

              dispatchUserInformations(
                { ...userData, backgroundColor: Color.GRAY_0 },
                dispatch,
                state
              )
              updateRoleName(userData.roleNames)

              try {
                if (organizationsList) {
                  const { entities } = await searchOrganizationByIds(
                    organizationsList,
                    history
                  )
                  const filterdEntities = entities.filter((entity) => {
                    return organizationsList.includes(entity.id)
                  })
                  if (filterdEntities)
                    dispatch({
                      type: ActionTypes.SET_ORGANIZATION_LIST,
                      payload: {
                        organizationList: filterdEntities,
                      },
                    })
                } else {
                  dispatch({
                    type: ActionTypes.SET_ORGANIZATION_LIST,
                    payload: {
                      organizationList: [],
                    },
                  })
                }

                if (institutionsList) {
                  const { entities } = await getEntreprisesByIds(
                    institutionsList,
                    history,
                    true
                  )

                  if (entities)
                    dispatch({
                      type: ActionTypes.SET_INSTITUTION_LIST,
                      payload: {
                        institutionList: entities,
                      },
                    })
                } else {
                  dispatch({
                    type: ActionTypes.SET_INSTITUTION_LIST,
                    payload: {
                      institutionList: [],
                    },
                  })
                }
              } catch (e) {
                console.debug('error', e.message)
              }

              dispatch({
                type: ActionTypes.SET_IS_IMPERSONNATION,
                payload: {
                  isImpersonnation: false,
                },
              })
              dispatch({
                type: ActionTypes.SET_USERNAME_IMPERSONNATION,
                payload: {
                  nameImpersonnated: '',
                  accessDemands: false,
                  emailImpersonated: '',
                },
              })

              handleNavigation(userData.roleNames, history)

              dispatch({
                type: ActionTypes.SET_API_CALL_BLOCK,
                payload: {
                  APICallBlocked: false,
                },
              })

              setIsLoading(false)
            }}
          >
            <Loading isLoading={isLoading} inButton>
              <SvgIcon name="stepBefore" className="menu__exitButton-icon" />
              {t('menu.exitImpersonnation')}
            </Loading>
          </Button>
        )}

        {isSubMenu(pathname) ? (
          <SubMenu />
        ) : (
          <MainMenu
            currentRoles={currentRoles}
            itemCollapsed={itemCollapsed}
            onItemCollapsed={handleitemCollapsed}
            activitiesMenuItems={activitiesMenuItems}
            billingMenuItems={billingMenuItems}
          />
        )}
        {!isImpersonnation && <NotificationMenu />}

        {!isImpersonnation ? (
          <MenuItem
            title={
              <>
                <SvgIcon name="bonhomme" className="profile-picture" />{' '}
                {firstname}
              </>
            }
            subItems={userMenuItems}
            itemId="user"
            isOpen={itemCollapsed === 'user'}
            onClick={handleitemCollapsed}
            className="menu__user"
          />
        ) : (
          <MenuItem
            title={
              <>
                <SvgIcon name="bonhomme" className="profile-picture" />{' '}
                {firstname}
              </>
            }
            className="impersonnation"
          />
        )}
      </div>
    </>
  )
}

export default Menu
