import AllRolesEntity from '../utils/AllRolesEntity'
import InstitutionEntity from '../utils/InstitutionEntity'
import OrganizationEntity from '../utils/OrganizationEntity'
import Color from '../utils/COLORS'
import PERIMETER_MODE from '../utils/PERIMETER_MODE'
import ViewMode from '../utils/VIEW_MODE'
import { DEFAULT_USERINFOS, UserEntity } from '../utils/UserEntity'
import { CREATIONMODIFY_STEP } from '../components/SubMenu'
import { SIGNUP_STEP } from '../components/SignUpSubMenu'
import ServiceEntity from '../utils/ServiceEntity'

export type UserType = {
  email: string
  userId: string
  isInternal: boolean
  firstname: string
  roleNames: string[]
  accessToken: string
  organizations: string[]
  byPassRecaptch: boolean
  accessDemands: boolean
  accessDocs: boolean
  idContactWdh: string
  isSuperAdminTech: boolean
}

export type UserInCreationType = {
  userId: string
}

export type OrganizationType = {
  items: OrganizationEntity[]
}

export type OrganizationValidationType = {
  organizationValidated: boolean
}

export type InstitutionType = {
  items: InstitutionEntity[]
}
export type ServiceType = {
  items: ServiceEntity[] | undefined
}
export type InstitutionPerimeterType = {
  allItems: InstitutionEntity[] | undefined
}

export type InstitutionValidationType = {
  institutionValidated: boolean
}

export type AllRolesType = {
  items: AllRolesEntity[]
}

export type UiType = {
  backgroundColor: string
  perimeterFilterMode: PERIMETER_MODE
  backHomeLeftBackgroundColor: string
  backHomeRightBackgroundColor: string
  viewMode: ViewMode
}

export type CreateModifyUserType = {
  isCreation: boolean
  userId: string
  step2Disabled: boolean
  step3Disabled: boolean
  actualStep: string
  userEntity: UserEntity
}
export type SignUpUserType = {
  step2Disabled: boolean
  step3Disabled: boolean
  actualStep: string
}

export type ImpersonnationType = {
  isImpersonnation: boolean
  nameImpersonnated: string
  accessDemands?: boolean
  emailImpersonated: string
}

export type ApiCallBlockType = {
  APICallBlocked: boolean
}

export type InitialStateType = {
  user: UserType
  userInCreation: UserInCreationType
  createModifyUser: CreateModifyUserType
  signUpUser: SignUpUserType
  organization: OrganizationType
  service: ServiceType
  institution: InstitutionType
  institutionPerimeter: InstitutionPerimeterType
  organizationValidation: OrganizationValidationType
  institutionValidation: InstitutionValidationType
  allRoles: AllRolesType
  ui: UiType
  impersonnation: ImpersonnationType
  apiBlock: ApiCallBlockType
}

export const initialState = {
  user: {
    email: '',
    userId: '',
    isInternal: false,
    byPassRecaptch: false,
    firstname: '',
    roleNames: [],
    accessToken: '',
    // TO DEBUG
    // organizations: ['1659'], // few results...
    // organizations: ['1630'], // more results !
    organizations: [], // with SUPER_ADMIN user, lot of results !!!
    accessDemands: false,
    accessDocs: false,
    idContactWdh: '',
    isSuperAdminTech: false,
  },
  userInCreation: {
    userId: '',
  },
  createModifyUser: {
    isCreation: true,
    userId: '',
    userEntity: DEFAULT_USERINFOS,
    step2Disabled: true,
    step3Disabled: true,
    actualStep: CREATIONMODIFY_STEP.STEP1_INFOS,
  },
  signUpUser: {
    step2Disabled: true,
    step3Disabled: true,
    actualStep: SIGNUP_STEP.STEP1_IDENTIFIER,
  },
  organization: {
    items: [],
  },
  service: {
    items: undefined,
  },
  organizationValidation: {
    organizationValidated: false,
  },
  institution: {
    items: [],
  },
  institutionPerimeter: {
    allItems: undefined,
  },
  institutionValidation: {
    institutionValidated: false,
  },
  allRoles: {
    items: [],
  },
  ui: {
    backgroundColor: Color.DEFAULT,
    perimeterFilterMode: PERIMETER_MODE.DECREASED_MODE,
    backHomeLeftBackgroundColor: Color.DEFAULT,
    backHomeRightBackgroundColor: Color.DEFAULT,
    viewMode:
      (window.localStorage.getItem('viewMode') as ViewMode) || ViewMode.Card,
  },
  impersonnation: {
    isImpersonnation: false,
    nameImpersonnated: '',
    accessDemands: false,
    emailImpersonated: '',
  },
  apiBlock: {
    APICallBlocked: false,
  },
}
