import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import qs from 'qs'
import Cookies from 'universal-cookie'
import SvgIcon from '../../../components/SvgIcon'

import Button from '../../../components/Button'
import ROUTES from '../../../utils/ROUTES'
import ScreenContainerSimpleLayoutConnexion from '../../../layouts/ScreenContainerSimpleLayoutConnexion'

import Loading from '../../../components/Loading'
import { resetEmail } from '../../../services/connexion-services'

interface params {
  userId: string
}

const EmailModified: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const location = useLocation<params>()
  const [loader, setIsLoading] = useState<boolean>(true)

  const checkToken = async () => {
    try {
      const { username: qUsername, token: qToken } = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      })
      await resetEmail(qUsername as string, qToken as string).then(() => {
        setIsLoading(false)
        const cookies = new Cookies()
        cookies.remove('refresh_token', { path: '/' })
        cookies.remove('current_user_roles', { path: '/' })
      })
    } catch (e) {
      history.push(ROUTES.EmailModifiedFail, { reason: e.message })
      setIsLoading(false)
    }
  }

  useEffect(() => {
    checkToken()
  }, [])

  return (
    <ScreenContainerSimpleLayoutConnexion isLogged={false}>
      {loader ? (
        <div className="email-modified">
          <div className="email-modified_text-container">
            <div className="content">
              <Loading isLoading inButton />
              <h2 className="loading-info">
                Veuillez patienter, modification en cours de votre e-mail
              </h2>
            </div>
          </div>
        </div>
      ) : (
        <div className="email-modified">
          <div className="email-modified_text-container">
            <div className="content">
              <SvgIcon name="circleCheck" className="email-modified_icon" />
              <h2 className="title">{t('emailModified.title')}</h2>
              <div>
                <p className="infos">{t('emailModified.info')}</p>
              </div>
              <Button onClick={() => history.push(ROUTES.CheckEmail)}>
                {t('login.button.reconnect')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </ScreenContainerSimpleLayoutConnexion>
  )
}

export default EmailModified
