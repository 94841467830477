import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import ScreenContainerSimpleLayoutConnexion from '../layouts/ScreenContainerSimpleLayoutConnexion'
import SvgIcon from './SvgIcon'
import Button from './Button'
import { UserData } from '../services/user-services'
import { AppContext } from '../store/context'
import { UserSignUpEntity } from '../utils/UserSignUpEntity'
import Instructions from './molecules/Email/Instructions'

interface Props {
  type: string
  redirectTo: string
  showButton?: boolean
  showIcon?: boolean
}

interface MailPasswordType {
  state: { mailType: string }
}

export const NOTIF_SIGNUP_CREATED = 'userSignUpCreated'
export const NOTIF_MAIL_PASSWORD_SENT = 'mailPasswordSent'
export const NOTIF_SIGN_UP_LINK = 'notifSignUp'

const SuccessNotification: React.FC<Props> = ({
  type,
  redirectTo,
  showButton,
  showIcon,
}: Props) => {
  const history = useHistory()
  const { t } = useTranslation()
  const {
    state: { user },
  } = useContext(AppContext)
  const { state } = useLocation<UserSignUpEntity>()
  const mailType = useLocation<MailPasswordType>()
  const [validity, setValidity] = useState<string | null>(null)

  const userData = {} as UserData
  userData.roleNames = [...user.roleNames]

  useEffect(() => {
    if (type === NOTIF_MAIL_PASSWORD_SENT)
      setValidity(
        Object.values(mailType.state)[0] === 'reinitialisation'
          ? ' valide 3 heures.'
          : ' valide 7 jours.'
      )
  }, [])

  return (
    <ScreenContainerSimpleLayoutConnexion>
      <div className="password-modified">
        <div className="password-modified_text-container">
          <div className="content">
            {showIcon && (
              <SvgIcon name="circleCheck" className="password-modified_icon" />
            )}

            {type === NOTIF_SIGNUP_CREATED && (
              <h2 className="title">
                {state?.allowedAdministrator
                  ? t('userSignUp.header.requesdCreated.title')
                  : t('userSignUp.header.userCreated.title')}
              </h2>
            )}

            <div>
              <div className="infos">
                {type === NOTIF_MAIL_PASSWORD_SENT && (
                  <>
                    {t('newpassword.mailSent')}
                    <Bold>{validity}</Bold>
                    <Instructions />
                  </>
                )}

                {type === NOTIF_SIGN_UP_LINK && (
                  <>
                    {t('newpassword.inscriptionLabel')}
                    <Link
                      className="user-sign-up-wrapper__link"
                      to={redirectTo}
                    >
                      {t('newpassword.inscriptionLink')}
                    </Link>
                  </>
                )}

                {type === NOTIF_SIGNUP_CREATED && (
                  <>
                    {state?.allowedAdministrator ? (
                      t('userSignUp.header.requesdCreated.subtitle')
                    ) : (
                      <>
                        {t('userSignUp.header.userCreated.subtitle.start')}
                        <Bold>
                          {t('userSignUp.header.userCreated.subtitle.validity')}
                        </Bold>
                        {t('userSignUp.header.userCreated.subtitle.end')}
                        <Instructions />
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            {showButton && (
              <Button onClick={() => history.push(redirectTo)}>
                {t('userSignUp.buttons.connect')}
              </Button>
            )}
          </div>
        </div>
      </div>
    </ScreenContainerSimpleLayoutConnexion>
  )
}

SuccessNotification.defaultProps = {
  showButton: true,
  showIcon: true,
}

const Bold = styled.span`
  font-weight: bold;
`

export default SuccessNotification
